<template>
  <v-progress-circular
    width="8"
    :size="sizes[0]"
    :rotate="rotates[0]"
    :color="colors[0]"
    indeterminate
  >
    <v-progress-circular
      width="8"
      :size="sizes[1]"
      :rotate="rotates[1]"
      :color="colors[1]"
      indeterminate
    >
      <v-progress-circular
        width="8"
        :size="sizes[2]"
        :rotate="rotates[2]"
        :color="colors[2]"
        indeterminate
      >
        <v-progress-circular
          width="8"
          :size="sizes[3]"
          :rotate="rotates[3]"
          :color="colors[3]"
          indeterminate
        >
          <v-progress-circular
            width="8"
            :size="sizes[4]"
            :rotate="rotates[4]"
            :color="colors[4]"
            indeterminate
          >
            <v-img contain width="200" :src="require('@/assets/icon.png')" />
          </v-progress-circular>
        </v-progress-circular>
      </v-progress-circular>
    </v-progress-circular>
  </v-progress-circular>
</template>

<script>
export default {
  name: "RGBSpinner",
  data() {
    return {
      number: 5,
      sizes: ["280", "260", "240", "220", "200"],
      rotates: ["225", "180", "135", "90", "45"],
      colors: ["primary", "primary", "primary", "primary", "primary"],
      intervalIDs: [],
    };
  },
  methods: {
    changeColor(index) {
      const blue = Math.floor(Math.random() * 255);
      this.$set(this.colors, index, `rgba(0, 0, ${blue}, 0.8)`);
    },
    clearIntervals() {
      this.intervalIDs.forEach((id) => {
        clearInterval(id);
      });
      this.intervalIDs = [];
    },
  },
  mounted() {
    for (let i = 0; i < 5; i++) {
      this.intervalIDs.push(setInterval(() => this.changeColor(i), 500));
    }
  },
  beforeDestroy() {
    if (this.intervalIDs.length === 0) {
      return;
    }
    this.clearIntervals();
  },
};
</script>
